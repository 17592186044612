import React, { useEffect, useMemo, useRef, useState } from 'react';
import CloudApi from '../../CloudApi';
import EXIF from '../../Utils/exif';
import config from '../../config';
import Table from '../Chrome/Elements/Table';
import styles from './MissionPhotos.module.scss';

export default function MissionPhotos({ missionId }) {
  return (
    <div>
      <DownloadZip missionId={missionId} />

      <PhotosTable missionId={missionId} />
    </div>
  );
}

function DownloadZip({ missionId }) {
  const [url, setUrl] = useState(null);

  function handleClick() {
    CloudApi.post(`missions/${missionId}/photosZip`).then(({ text }) => {
      setUrl(`${config.dkcUrl}${text}`);
    });
  }

  function handleCopy(event) {
    const value = event.target.closest('div').querySelector('a').href;
    navigator.clipboard.writeText(value);
  }

  return (
    <div>
      {url ? (
        <div>
          <div>
            <a href={url} download>
              Download zip with original file names
            </a>

            <button type="button" onClick={handleCopy}>
              Copy URL to Clipboard
            </button>
          </div>

          <div>
            <a href={`${url}&namefmt=id`} download>
              Download zip with Site Scan ID file names
            </a>

            <button type="button" onClick={handleCopy}>
              Copy URL to Clipboard
            </button>
          </div>
        </div>
      ) : (
        <button type="button" onClick={handleClick}>
          Generate Download URL
        </button>
      )}
    </div>
  );
}

function PhotosTable({ missionId }) {
  const [photos, setPhotos] = useState(null);

  const abortController = useRef(null);
  if (abortController.current === null) {
    abortController.current = new AbortController();
  }

  useEffect(() => {
    CloudApi.get(`missions/${missionId}/media`).then(({ body }) =>
      setPhotos(body),
    );

    return () => {
      abortController.current.abort();
    };
  }, [missionId]);

  const [sortField, setSortField] = useState();
  const [isAscending, setIsAscending] = useState(true);

  function handleFieldSort(field) {
    setSortField((prevSortField) => {
      if (prevSortField === field) {
        setIsAscending((prevIsAscending) => !prevIsAscending);
      }

      return field;
    }, []);
  }

  const sortedPhotos = useMemo(() => {
    if (!photos) return null;

    if (!sortField) {
      return photos;
    }

    let getValue;

    if (sortField === 'dimensions') {
      getValue = (photo) => {
        return photo.dimensions
          ? photo.dimensions.width * photo.dimensions.height
          : 0;
      };
    } else {
      const fieldArray = sortField.split('.');

      getValue = (photo) => {
        return fieldArray.reduce(
          (prevObj, key) => prevObj && prevObj[key],
          photo,
        );
      };
    }

    return photos.toSorted((a, b) => {
      const aValue = getValue(a);
      const bValue = getValue(b);

      if (aValue === bValue) return 0;

      if (aValue < bValue) {
        return isAscending ? -1 : 1;
      } else {
        return isAscending ? 1 : -1;
      }
    });
  }, [isAscending, photos, sortField]);

  if (sortedPhotos === null) {
    return null;
  }

  const hasBand = sortedPhotos.some((p) => p.band);
  const hasProblem = sortedPhotos.some((p) => p.problem);

  return (
    <Table className={styles.photoTable}>
      <thead>
        <tr>
          <th>
            <SortButton
              value="id"
              onClick={handleFieldSort}
              sortField={sortField}
              isAscending={isAscending}
            />
          </th>
          <th>
            <SortButton
              value="name"
              onClick={handleFieldSort}
              sortField={sortField}
              isAscending={isAscending}
            />
          </th>
          {hasProblem && (
            <th>
              <SortButton
                value="problem"
                onClick={handleFieldSort}
                sortField={sortField}
                isAscending={isAscending}
              />
            </th>
          )}
          <th>
            <SortButton
              value="cameraMake"
              onClick={handleFieldSort}
              sortField={sortField}
              isAscending={isAscending}
            />
          </th>
          <th>
            <SortButton
              value="cameraModel"
              onClick={handleFieldSort}
              sortField={sortField}
              isAscending={isAscending}
            />
          </th>
          <th>
            <SortButton
              value="dimensions"
              onClick={handleFieldSort}
              sortField={sortField}
              isAscending={isAscending}
            />
          </th>
          {hasBand && (
            <th>
              <SortButton
                value="band"
                onClick={handleFieldSort}
                sortField={sortField}
                isAscending={isAscending}
              />
            </th>
          )}
          <th>download</th>
          <th>exif</th>
        </tr>
      </thead>
      <tbody>
        {sortedPhotos.map((photo) => (
          <PhotoRow
            key={photo.id}
            photo={photo}
            showBandColumn={hasBand}
            showProblemColumn={hasProblem}
          />
        ))}
      </tbody>
    </Table>
  );
}

function PhotoRow({ photo, showBandColumn, showProblemColumn }) {
  const [exif, setExif] = useState();
  const [isViewingExif, setIsViewingExif] = useState(false);

  function handleFetchExif() {
    if (exif) {
      setIsViewingExif(true);
    } else {
      fetchExif(photo.url).then((data) => {
        setExif(data);
        setIsViewingExif(true);
      });
    }
  }

  function handleCloseExif() {
    setIsViewingExif(false);
  }

  function handleCopyExif() {
    navigator.clipboard.writeText(JSON.stringify(exif));
  }

  return (
    <tr key={photo.id}>
      <td>
        <pre className={styles.photoId}>{photo.id}</pre>
      </td>
      <td>
        <pre>{photo.name}</pre>
      </td>
      {showProblemColumn && <td className={styles.problem}>{photo.problem}</td>}
      <td>{photo.cameraMake}</td>
      <td>{photo.cameraModel}</td>
      <td>
        {photo.dimensions &&
          `${photo.dimensions.width}×${photo.dimensions.height} (${Math.round(
            (photo.dimensions.width * photo.dimensions.height) / 1e6,
          )} MPx)`}
      </td>
      {showBandColumn && <td>{photo.band || 'rgb'}</td>}
      <td>
        <a href={photo.url} download>
          Download
        </a>
      </td>
      <td>
        <button onClick={handleFetchExif}>View Exif</button>

        {isViewingExif ? (
          <dialog open>
            <button onClick={handleCloseExif}>close</button>
            <button onClick={handleCopyExif}>copy to clipboard</button>
            <table className={styles.exifTable}>
              <tbody>
                {Object.entries(exif).map(([key, value]) => (
                  <tr key={key}>
                    <th scope="row">{key}</th>
                    <td>{value?.toString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </dialog>
        ) : null}
      </td>
    </tr>
  );
}

async function fetchExif(url, options = {}) {
  const resp = await fetch(url, {
    ...options,
    headers: { range: 'bytes=0-128000' },
  });
  const buffer = await resp.arrayBuffer();
  return EXIF.readFromBinaryFile(buffer);
}

function SortButton({ value, sortField, isAscending, onClick }) {
  const direction = value === sortField ? (isAscending ? '↑' : '↓') : null;

  return (
    <button onClick={() => onClick(value)}>
      {value}
      {direction}
    </button>
  );
}
